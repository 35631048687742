import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
// import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
// import { getBuildingAddress } from 'contracts/accommodations/helpers/getBuildingAddress'
import bg from './bg.png'

export class Lfgb2 extends React.Component<ResidentCardProps, {}> {
  private getAccommodation = (): {
    roomLabel: string
    responsibilityBeginDateDe: string
  } => {
    const bookings = this.props.resident.data?.bookings ?? []
    bookings.sort((a, b) => (a.beginAt < b.beginAt ? -1 : 1))
    const current = bookings.filter(
      (b) => b.timeline === 'present' && b.type === 'internal-residence',
    )[0]
    if (!current) {
      return { roomLabel: '-', responsibilityBeginDateDe: '-' }
    }
    let responsitility: any = null
    for (const booking of bookings) {
      if (booking.beginAt > current.beginAt) {
        break
      }
      if (booking.type === 'responsibility-begin') {
        responsitility = booking
      }
      if (booking.type === 'responsibility-end') {
        responsitility = null
      }
    }
    return {
      roomLabel: current.label?.split(' / ').pop() || '',
      responsibilityBeginDateDe: responsitility
        ? dayjs(responsitility.beginAt).format('DD.MM.YYYY')
        : '-',
    }
  }

  render() {
    const accommodation = this.getAccommodation()
    console.log(accommodation)
    return (
      <div
        className={styles.page}
        style={
          this.props.batch
            ? {}
            : {
                pageBreakAfter: 'always',
                backgroundImage: `url(${bg})`,
                backgroundSize: 'contain',
              }
        }
      >
        <div className='bg-white font-bold'>Max Engelmann der Dritte</div>
        <div className='bg-white'>Max Engelmann der Dritte</div>
      </div>
    )
  }
}
