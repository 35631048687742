import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Callout } from 'components/Callout'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { useCollection } from 'helpers/useCollection'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  filter: Model<{ companyId: string; page: string }>
  selected?: string
}

export const TopicList: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const navigate = useNavigate()
  const topics = useCollection<IEmployeeCommTopic>(
    `/api/${context.instance.id}/employeeComm/topics`,
    { observeQuery: true, readOnly: true },
    props.filter.values,
  )

  return (
    <div className='mt-4 flex flex-col gap-2'>
      {topics.error?.id === RequestPendingError.id && <Spinner delay />}
      {topics.error && topics.error.id !== RequestPendingError.id && (
        <LoadingError title='Nachrichten konnten nicht geladen werden' />
      )}
      {topics.resources?.map((topic) => (
        <div
          onClick={() => navigate(`/employee/communication/${topic.id}`)}
          key={topic.id}
          className={
            props.selected === topic.id
              ? 'cursor-pointer bg-gray-100 text-gray-900 border-2 border-indigo-500 group relative flex items-center px-2 py-2 text-sm font-medium rounded-md truncate'
              : 'cursor-pointer text-gray-600 border-2 border-gray-100 hover:bg-gray-50 hover:text-gray-900 group relative flex items-center px-2 py-2 text-sm font-medium rounded-md truncate'
          }
        >
          {topic.data?.label}
        </div>
      ))}
      {topics.resources && topics.resources.length === 0 && (
        <Callout
          title='Keine Nachrichten vorhanden'
          subtitle='Klicken Sie auf den Button "Neu", um Ihre erste Nachricht zu erstellen.'
          icon='fas fa-folder-open'
          className='mt-10'
        />
      )}
    </div>
  )
})
