import * as React from 'react'
import { SideBarLayout } from 'components/SideBarLayout'
import { ReportingSideBar } from './components/ReportingSideBar'
import { Route, Routes } from 'react-router'
import { AccommodationBookings } from './AccommodationBookings'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { ReportingTopBar } from './components/ReportingTopBar'
import { MealReport } from './MealReport'
import { Belegungsauswertung } from './Belegungsauswertung'
import { OccupancyFigures } from './OccupancyFigures'
import { EssenslisteRob } from './EssenslisteRob'
import { BelegungsplanRob } from './BelegungsplanRob'
import { Bewohnerstatistik } from './Bewohnerstatistik'
import { Belegungsstatistik } from './Belegungsstatistik'
import { Hygienemittelstatistik } from './Hygienemittelstatistik'
import { BelegungsaenderungenRob } from './BelegungsaenderungenRob'
import { BelegungsaenderungenGarmischer } from './BelegungsaenderungenRob/BelegungsaenderungenGarmischer'
import { Hygienemittelbestand } from './Hygienemittelbestand'
import { FehlendeKUEs } from './FehlendeKUEs'
import { Ticketsauswertung } from './Ticketsauswertung'
import { Laenderstatistik } from './Laenderstatistik'
import { QuarantineList } from './QuarantineList'
import { BelegungshistorieSoz3 } from './BelegungshistorieSoz3'
import { CapacityEvaluation } from './CapacityEvaluation'
import { AwumBettenstatistik } from './AwumBettenstatistik'

interface Props {}

export interface IReport {
  key: string
  label: string
  element: React.ReactElement
  permission: string
}

const reports: IReport[] = [
  {
    key: 'accommodation-bookings',
    label: 'Belegungsplan',
    element: (
      <AccommodationBookings key='accommodation-bookings' type='accommodation-bookings' />
    ),
    permission: 'report_belegungsplan',
  },
  {
    key: 'accommodation-bookings-2',
    label: 'Belegungsplan 2',
    element: (
      <AccommodationBookings
        key='accommodation-bookings-2'
        type='accommodation-bookings-2'
      />
    ),
    permission: 'report_belegungsplan2',
  },
  {
    key: 'accommodation-bookings-3',
    label: 'Belegungsplan Neu',
    element: (
      <AccommodationBookings
        key='accommodation-bookings-3'
        type='accommodation-bookings-3'
      />
    ),
    permission: 'report_belegungsplan3',
  },
  {
    key: 'belegungsauswertung',
    label: 'Anonyme Belegungsauswertung',
    element: <Belegungsauswertung />,
    permission: 'report_belegungsauswertung',
  },
  {
    key: 'belegungszahlen',
    label: 'Belegungszahlen',
    element: <OccupancyFigures />,
    permission: 'report_belegungszahlen',
  },
  {
    key: 'belegungshistorie-soz3',
    label: 'Belegungshistorie S-III',
    element: <BelegungshistorieSoz3 />,
    permission: 'report_belegungshistorie_soz3',
  },
  {
    key: 'awum-bettenstatistik',
    label: 'AWUM Bewohnerstatistik',
    element: <AwumBettenstatistik />,
    permission: 'report_awumStatistik',
  },
  {
    key: 'belegungsaenderungenRob',
    label: 'Belegungsänderungen ROB',
    element: <BelegungsaenderungenRob />,
    permission: 'report_belegungsaenderungen_rob',
  },
  {
    key: 'belegungsaenderungenGarmischer',
    label: 'Belegungsänderungen Garmischer',
    element: <BelegungsaenderungenGarmischer />,
    permission: 'report_belegungsaenderungen_garmischer',
  },
  /*{
    key: 'lea-occupancy',
    label: 'LEA Belegungsplan',
    element: <LeaBelegungsplan />,
    permission: 'report_leaOccupancy'
  },
  {
    key: 'country-stats',
    label: 'LEA Länderstatistik',
    element: <LeaCountryStats />,
    permission: 'report_leaOccupancyByCountry',
  },*/
  {
    key: 'meal-report',
    label: 'Essensausgabe',
    element: <MealReport />,
    permission: 'report_meals',
  },
  {
    key: 'belegungsplan-rob',
    label: 'Belegungsplan ROB',
    element: <BelegungsplanRob />,
    permission: 'report_belegungsplan_rob',
  },
  /*{
    key: 'freiplatzmeldung-rob',
    label: 'Freiplatzmeldung ROB',
    element: <FreiplatzmeldungRob />,
    permission: 'report_freiplatzmeldung_rob'
  },*/
  {
    key: 'essensliste-rob',
    label: 'Essensliste ROB',
    element: <EssenslisteRob />,
    permission: 'report_essensliste_rob',
  },
  /*{
    *** Added for brk, but brk does not use inuv anymore ***
    key: 'bewohnerabrechnung',
    label: 'Bewohnerabrechnung',
    element: <BewohnerabrechnungReport />,
    permission: 'report_bewohnerabrechnung',
  },*/
  {
    key: 'bewohnerstatistik',
    label: 'Bewohnerstatistik',
    element: <Bewohnerstatistik />,
    permission: 'report_bewohnerstatistik',
  },
  {
    key: 'belegungsstatistik',
    label: 'Belegungsstatistik',
    element: <Belegungsstatistik />,
    permission: 'report_belegungsstatistik',
  },
  {
    key: 'hygienemittelstatistik',
    label: 'Hygienemittelstatistik',
    element: <Hygienemittelstatistik />,
    permission: 'report_hygienemittelstatistik',
  },
  {
    key: 'hygienemittelbestand',
    label: 'Hygienemittelbestand',
    element: <Hygienemittelbestand />,
    permission: 'report_hygienemittelbestand',
  },
  {
    key: 'fehlende_kues',
    label: 'Fehlende KÜs',
    element: <FehlendeKUEs />,
    permission: 'report_fehlende_kues',
  },
  {
    key: 'tickets',
    label: 'Ticketsauswertung',
    element: <Ticketsauswertung />,
    permission: 'report_ticketsauswertung',
  },
  {
    key: 'bewohnerliste_rpt',
    label: 'Bewohnerliste RPT',
    element: <AccommodationBookings key='bewohnerliste_rpt' type='bewohnerliste_rpt' />,
    permission: 'report_bewohnerliste_rpt',
  },
  {
    key: 'laenderstatistik',
    label: 'Länderstatistik',
    element: <Laenderstatistik />,
    permission: 'report_laenderstatistik',
  },
  {
    key: 'quarantaeneliste',
    label: 'Quarantäneliste',
    element: <QuarantineList />,
    permission: 'report_quarantaeneliste',
  },
  {
    key: 'capacityEvaluation',
    label: 'Kapazitätsauswertung',
    element: <CapacityEvaluation />,
    permission: 'report_capacityEvaluation',
  },
  /*{
    key: 'stundennachweise',
    label: 'Stundennachweise',
    element: <Stundennachweise />,
    permission: 'report_stundennachweise',
  },*/
]

export class Reporting extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    if (!this.context.permissions.menu_reports) {
      return <Forbidden />
    }

    return (
      <SideBarLayout>
        <ReportingSideBar reports={reports} />
        <div className='md:pl-80 pt-14 flex flex-col h-screen'>
          <ReportingTopBar reports={reports} />
          <Routes>
            <Route
              path='/'
              element={
                <div className='m-auto w-fit pb-10 text-gray-500'>
                  Es wurde noch kein Report ausgewählt.{' '}
                </div>
              }
            />
            {reports.map((report) => (
              <Route
                key={report.key}
                path={`/${report.key}`}
                element={<div className='my-12 mx-12'>{report.element}</div>}
              />
            ))}
          </Routes>
        </div>
      </SideBarLayout>
    )
  }
}
