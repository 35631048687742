import { Resource } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { Spinner } from 'components/Spinner'
import {
  IEmployeeCommThread,
  IEmployeeCommThreadSalarySlipExtra,
} from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { observer } from 'mobx-react'
import { PdfPreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/PdfPreview'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  topicId: string
  threadId: string
}

@observer
export class EmployeeSalarySheetPreview extends React.Component<Props, {}> {
  static contextType = AppContext
  private thread: Resource<IEmployeeCommThread>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.thread = new Resource(
      `/api/${context.instance.id}/employeeComm/topics/${props.topicId}/threads/${props.threadId}`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.thread.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    if (this.thread.error?.id === NotFoundError.id) {
      return (
        <Callout
          title='Gehaltszettel nicht gefunden'
          icon='far fa-lemon text-yellow-400'
        />
      )
    }

    if (!this.thread.data) {
      return <Spinner delay />
    }

    const extra: IEmployeeCommThreadSalarySlipExtra = this.thread.data.extra
    return (
      <div className='absolute top-6 bottom-6 right-6 overflow-x-hidden overflow-y-auto'>
        <div className='bg-white flex gap-2 p-4'>
          {extra.checks.map((check, index) => (
            <span
              key={index}
              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-white ${
                check.found ? 'bg-green-500' : 'bg-red-500'
              }`}
            >
              {check.word}
            </span>
          ))}
          {extra.checks.length === 0 &&
            extra.errors.length > 0 &&
            extra.errors.map((error, index) => (
              <span
                key={index}
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-white bg-yellow-500`}
              >
                {error}
              </span>
            ))}
        </div>
        <PdfPreview
          url={`/api/${this.context.instance.id}/documents/files/${this.thread.data.attachment?.id}`}
        />
      </div>
    )
  }
}
