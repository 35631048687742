import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { configure, onReactionError } from 'mobx'
import React from 'react'
import ReactDOM from 'react-dom'
import { env } from './env'
import * as connection from './services/connection'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'
import '@fortawesome/fontawesome-free/js/all'
import { GlobalRoutes } from './GlobalRoutes'
import { getAppStatus } from 'services/connection/helpers/getAppStatus'
import { withTimeout } from 'helpers/withTimeout'
import { BoxMount } from 'services/box'
import { InstanceNotFound } from 'modules/ErrorPages/InstanceNotFound'
import { isPlainObject } from 'helpers/isPlainObject'
import { Login } from 'modules/Login'
import 'flat-map-polyfill'

async function bootstrap() {
  // Allow new tabs/windows for downloads that should just open with empty page.
  // This is also used as initial cookie fetching page for pdf-generation.
  // Beware: In prod there is an empty page served by nginx for /blank.
  if (window.location.pathname === '/blank') {
    return
  }

  // Initialize Bugsnag error reporting for production
  let appStatus = getAppStatus()

  // Domain name points to inuv, but instance not registered.
  if (!appStatus.instanceId) {
    ReactDOM.render(<InstanceNotFound />, document.getElementById('root'))
    return
  }

  Bugsnag.start({
    apiKey: env.BUGSNAG_API_KEY,
    appVersion: env.VERSION,
    releaseStage: env.NODE_ENV,
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginReact()],
    collectUserIp: false,
    user: { id: `i${appStatus.instanceId}/u${appStatus.userId}` },
    metadata: {
      instance: {
        id: appStatus.instanceId,
        host: window.location.hostname,
      },
    },
    onError: function (event) {
      if (
        isPlainObject(event.originalError) &&
        event.originalError.base === 'HermesError'
      ) {
        event.severity = 'info'
      }
    },
  })

  // This method attaches a global error listener, which is invoked for every
  // error that is thrown from a reaction. This is used for monitoring purposes.
  onReactionError((error) => Bugsnag.notify(error))

  // Turn on mobx strict mode because it is best practice to use actions and batch
  // reactions. Only enforce this in dev to avoid production errors in case of missing actions.
  if (env.NODE_ENV === 'development') {
    configure({ enforceActions: 'observed' })
  }

  // Login page
  if (!appStatus.sessionId) {
    ReactDOM.render(
      <>
        <Login appStatus={appStatus} />
        <BoxMount />
      </>,
      document.getElementById('root'),
    )
    return
  }

  // Establish hermes connection and prefetch session (reconnect / disconnect handling is
  // performed by subpath that really requires hermes connection. Other routes are not
  // affected by disconnect.
  if (!window.location.pathname.startsWith('/pdf')) {
    try {
      await withTimeout(connection.init, 7000)(appStatus)
    } catch (_e) {
      // Use different object for app status. This way the timed out
      // connection init cannot change connection state afterwards
      appStatus = getAppStatus()
    }
  }

  // Bugsnag error handler
  const ErrorView = () => (
    <div>
      <p className='p-4'>
        Diese Ansicht konnte nicht dargestellt werden. Bitte laden Sie die Seite erneut.
      </p>
    </div>
  )
  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

  // First render after loader
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorView}>
      <BrowserRouter>
        <GlobalRoutes appStatus={appStatus} />
        <BoxMount />
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root'),
  )
}

void bootstrap()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
